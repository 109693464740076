
import { watchDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_DOC_PATH, AGENT_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'


const initState = () => ({
  agency: undefined,
  agencyUnsubscribe: undefined,

  agencyAgent: undefined,
  agenctUserUnsubscribe: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    reinit(state) {
      state.agencyUnsubscribe?.()
      state.agenctUserUnsubscribe?.()
      Object.assign(state, initState())
    },
    setAgency(state, agency) {
      Object.assign(state, { agency })
    },
    setAgencyUnsubscribe(state, agencyUnsubscribe) {
      Object.assign(state, { agencyUnsubscribe })
    },
    setAgencyAgent(state, agencyAgent) {
      Object.assign(state, { agencyAgent })
    },
    setAgencyAgentUnsubscribe(state, agenctUserUnsubscribe) {
      Object.assign(state, { agenctUserUnsubscribe })
    },
  },

  getters: {
    agencyTabList(state, _, rootState) {
      const { agency, agencyAgent } = state
      const { agencyRole } = rootState.currentRoute?.params || {}
      if (!agencyRole || !agency || !agencyAgent) return []

      const isAgencyAdmin = agencyRole === 'admin'
      const { agencyType } = agency

      const isTalentAgency = ['singleRosterTalentAgency', 'multiRosterTalentAgency'].includes(agencyType)

      const list = []

      if (isTalentAgency) {
        list.push({
          targetRoute: { name: isAgencyAdmin ? 'agencyAdminStats' : 'agencyAgentStats' },
          title: 'Overview',
        })
      }

      if (isAgencyAdmin) {
        list.push({
          targetRoute: { name: 'agencyTeam' },
          title: isTalentAgency ? 'Agents' : 'Team',
        })
      }

      if (isTalentAgency) {
        list.push({
          targetRoute: { name: 'agencyCreators' },
          title: 'Creators',
        })

        list.push({
          targetRoute: { name: isAgencyAdmin ? 'agencyAdminCreatorLists' : 'agencyAgentCreatorLists' },
          title: 'Creator Lists',
        })
      }

      list.push({
        targetRoute: { name: 'agencyCampaigns' },
        title: 'Campaigns',
      })

      list.push({
        targetRoute: { name: isAgencyAdmin ? 'agencyAdminWallet' : 'agencyAgentWallet' },
        title: 'Wallet',
      })

      list.push({
        targetRoute: { name: isAgencyAdmin ? 'agencyAdminSettings' : 'agencyAgentSettings' },
        title: 'Settings',
      })

      return list
    },
  },

  actions: {
    init({ commit, rootState }, { agencyId }) {
      commit('reinit')

      const { signedInUserId } = rootState
      if (!signedInUserId) return

      const agencyUnsubscribe = watchDoc(AGENCY_DOC_PATH(agencyId), (agencyDoc) => {
        commit('setAgency', { id: agencyDoc.id, ...(agencyDoc.data() || {}) })
      })
      commit('setAgencyUnsubscribe', agencyUnsubscribe)

      const agencyAgentUnsubscribe = watchDoc(AGENT_DOC_PATH(agencyId, signedInUserId), (agencyAgentDoc) => {
        commit('setAgencyAgent', { id: agencyAgentDoc.id, ...(agencyAgentDoc.data() || {}) })
      })
      commit('setAgencyAgentUnsubscribe', agencyAgentUnsubscribe)
    },
  },
}
